import EsfirusButtonNative from '@components/ui/Button/EsfirusButtonNative';
import { getAvailableCompanies } from '@helpers/companyData';
import { partesService } from '@services/partes';
import {
  resetPartes,
  setSelectedDateFrom,
  setSelectedDateTo,
  setSelectedEmpleado,
  setSelectedEmpresa,
  setSelectedObra,
  setSelectedResponsable,
} from '@store/slices/partes';
import EsfirusSearch from '@ui/Search/EsfirusSearch';
import EsfirusSelect from '@ui/Select/EsfirusSelect';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModalMsg from '../modalMsg/ModalMsg';
import './subheader.scss';

import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import 'dayjs/locale/es';

import EsfirusTextInput from '@components/ui/Text-input/EsfirusTextInput';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import usePartesGet from '@services/hooks/usePartesGet';
import ModalValidating from '../ModalValidating/ModalValidating';

function PartesSubHeader(props: any) {
  const getPartes = usePartesGet();

  const empresas = useSelector((state) => (state as any).empresas).list;
  const obras = useSelector((state) => (state as any).obras).list;
  const filterByType = useSelector(
    (state) => (state as any).partes
  ).filterByType;

  const dispatch = useDispatch();

  const [empresasDisponibles, setEmpresasDisponibles] = useState<any>([]);
  const [empresasList, setEmpresasList] = useState<any>([]);
  const [obrasList, setObrasList] = useState<any>([]);
  const { search, setFilterBySearch } = props;
  const [msg, setMsg] = useState<any>('');
  const [open, setOpen] = useState<any>(false);

  const [isValidating, setIsValidating] = useState(false); // Para saber si se está validando el parte
  const [showModalValidating, setShowModalValidating] = useState(false); // Para mostrar el modal de validando un parte

  const partesLength = useMemo(
    () => props?.partes?.length ?? 0,
    [props?.partes]
  );
  const pendingSelectedLength = useMemo(
    () =>
      Object.values(props?.pendingSelected ?? {}).filter((val) => !!val)
        ?.length,
    [props?.pendingSelected]
  );

  useEffect(() => {
    setEmpresasDisponibles(getAvailableCompanies());
    // selectEmpresa(getInternalCompany());
    setObrasList(obras);
  }, []);

  useEffect(() => {
    setEmpresasList(
      empresas.filter(
        (e: any) =>
          empresasDisponibles === '*' || empresasDisponibles.includes(e.Empresa)
      )
    );
  }, [empresasDisponibles]);

  const selectEmpresa = (empresa: any) => {
    dispatch(setSelectedEmpresa(empresa));
    //deseleccionamos la obra si estubiera filrada
    dispatch(setSelectedObra(null as never));

    dispatch(setSelectedEmpleado(null as never));
    dispatch(setSelectedResponsable(null as never));

    setObrasList(obras.filter((e: any) => e.Empresa === empresa));
  };

  const updatePromise = () => dispatch(getPartes(0, 50, filterByType) as any);

  const hasPartesSelected =
    Object.entries(props.pendingSelected).filter((e: any) => e[1]).length > 0;

  const handleValidateSelection: VoidFunction = async () => {
    setShowModalValidating(true);
    setIsValidating(true);

    try {
      const { pendingSelected } = props;
      const allTrue = Object.values(pendingSelected).every(Boolean);
      const forValidate = Object.keys(pendingSelected).filter(
        (key) => pendingSelected[key]
      );

      if (forValidate.length === 0) {
        setIsValidating(false);
        return;
      }

      await Promise.all(
        forValidate.map((id) => partesService.confirmarParte(id))
      );

      await updatePromise();

      if (allTrue || pendingSelectedLength >= partesLength) {
        dispatch(resetPartes({} as any));
      }

      setOpen(false);
    } catch (error) {
      console.error('Error en la validación:', error);
    } finally {
      setIsValidating(false);
    }
  };

  return (
    <div className="ef-pates-subheader">
      <div className="ef-left">
        <EsfirusSelect
          key={'ef-select-company'}
          options={empresasList.map((e: any) => ({
            label: e.Nombre,
            value: e.Empresa,
          }))}
          value={props.selectedEmpresa}
          change={(e: any) => {
            if (e === '') {
              selectEmpresa(null as never);
            } else {
              selectEmpresa(
                empresas.find(
                  (_e: any) => _e.Empresa.toString() === e.toString()
                ).Empresa
              );
            }
          }}
          placeholder="Todas"
        />

        <EsfirusSelect
          style={{ maxWidth: window.innerWidth > 500 ? '500px' : '0px' }}
          key={'ef-select-obra'}
          options={obrasList.map((e: any) => ({
            label: e.Obra + ' - ' + e.Descripcion,
            value: e.Obra,
          }))}
          value={props.selectedObra}
          change={(e: any) => {
            if (e === '') {
              dispatch(setSelectedObra(null as never));
            } else {
              dispatch(
                setSelectedObra(obras.find((_e: any) => _e.Obra === e).Obra)
              );
            }
          }}
          placeholder="Obra"
        />

        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
          <DatePicker
            value={props.selectedDateFrom}
            onChange={(newValue) => {
              const date = new Date(newValue.$y, newValue.$M, newValue.$D);
              dispatch(setSelectedDateFrom(date?.getTime() as never));
            }}
            renderInput={(params) => <EsfirusTextInput fullWidth {...params} />}
            inputFormat="DD/MM/YYYY"
            className="datePickerFilter"
          />
        </LocalizationProvider>

        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
          <DatePicker
            value={props.selectedDateTo}
            onChange={(newValue) => {
              const date = new Date(newValue.$y, newValue.$M, newValue.$D);
              dispatch(setSelectedDateTo(date?.getTime() as never));
            }}
            renderInput={(params) => <EsfirusTextInput fullWidth {...params} />}
            inputFormat="DD/MM/YYYY"
            className="datePickerFilter"
          />
        </LocalizationProvider>

        <EsfirusSearch
          value={search}
          change={(e: any) => setFilterBySearch(e as never)}
          placeholder="Buscar"
        />

        <EsfirusSelect
          key={'ef-select-employee'}
          options={props?.empleadosList}
          value={props?.selectedEmpleado}
          change={(e: any) => {
            if (e === '') {
              dispatch(setSelectedEmpleado(null as never));
            } else {
              dispatch(setSelectedEmpleado(e));
            }
          }}
          placeholder="Todos los Empleados"
        />

        <EsfirusSelect
          key={'ef-select-responsable'}
          options={props?.responsablesList}
          value={props?.selectedResponsable}
          change={(e: any) => {
            if (e === '') {
              dispatch(setSelectedResponsable(null as never));
            } else {
              dispatch(setSelectedResponsable(e));
            }
          }}
          placeholder="Todos los Responsables"
        />
      </div>
      <div className="ef-right">
        <EsfirusButtonNative
          className={'validate'}
          label={'Validar selección'}
          disabled={!hasPartesSelected}
          click={handleValidateSelection}
        ></EsfirusButtonNative>
        <EsfirusButtonNative
          className={'rejected'}
          label={'Rechazar selección'}
          disabled={!hasPartesSelected}
          click={() => {
            setMsg('');
            setOpen(true);
          }}
        ></EsfirusButtonNative>
      </div>
      <ModalMsg
        open={open}
        setOpen={setOpen}
        onMsg={(m: string) => {
          setMsg(m);
        }}
        onSave={async () => {
          const paraRechazar = Object.entries(props.pendingSelected).filter(
            (e: any) => e[1]
          );
          const promises = paraRechazar.map((p: any) =>
            partesService.rechazarParte(p[0], msg)
          );
          await Promise.all(promises);
          await updatePromise();
          if (pendingSelectedLength >= partesLength) {
            dispatch(resetPartes({} as any));
          }
          setOpen(false);
        }}
      ></ModalMsg>

      {showModalValidating && (
        <ModalValidating
          open={showModalValidating}
          setOpen={setShowModalValidating}
          isValidating={isValidating}
        />
      )}
    </div>
  );
}

export default PartesSubHeader;
